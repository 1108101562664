<template>
  <v-row>
    <v-col>
      <div class="mb-5">
        <v-row
          align="stretch"
          no-gutters
        >
          <v-col>
            <SearchKeyword :filter="filter" />
          </v-col>
        </v-row>
      </div>

      <v-card>
        <v-toolbar flat>
          <v-icon>mdi-domain</v-icon>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-toolbar-title class="d-none d-sm-flex align-center">
            <span>{{ camelToSentence($options.name) }}</span>
          </v-toolbar-title>
          <v-spacer />

          <v-btn
            color="primary"
            dark
            @click="openForm(null)"
          >
            Add
          </v-btn>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="meta.total"
          :loading="loading"
          disable-sort
          mobile-breakpoint="0"
          :footer-props="footerProps"
        >
          <!-- name -->
          <template v-slot:item.name="{ item }">
            <router-link
              :to="`/organizations/${item.id}`"
              class="body-1 font-weight-bold no-underline"
              v-html="highlightString(item.name,routeQuery.keyword)"
            />
          </template>
          <!-- actions -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="mr-2"
                  v-on="on"
                  @click="openForm(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  color="error"
                  class="mr-2"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>

      <OrganizationForm
        ref="formModal"
        :item="item"
        @reload-data="handlingReload"
      />
    </v-col>
  </v-row>
</template>

<script>
import DatalistMixin from '../../mixins/DatalistMixin';
import SearchKeyword from '../../components/SearchKeyword.vue';
import OrganizationForm from './OrganizationForm.vue';

export default {
  name: 'OrganizationList',
  components: { SearchKeyword, OrganizationForm },
  mixins: [DatalistMixin],
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          width: '200px',
        },
        {
          text: 'Description',
          value: 'description',
          width: '400px',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '120px',
        },
      ],
      filter: {
        page: 1,
        keyword: null,
      },
    };
  },

  mounted() {
    // event from delete data
    this.$on('reload-data', () => {
      this.handlingReload();
    });
  },

  methods: {
    getData() {
      return this.$api.getOrganizations({
        ...this.filter,
        name: this.filter.keyword, // organization use 'name' filter, not 'keyword' filter
      });
    },

    deleteData(orgId) {
      return this.$api.deleteOrganization(orgId);
    },

    handlingReload(event = null) {
      // update vuex store - no paginate
      this.$store.dispatch('getOrganizations', {
        forceGet: true,
      });

      // update local store - with paginate
      this.getItem();
    },
  },
};
</script>
